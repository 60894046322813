<template>
  <div>
    <button v-if="mediaData.length > 1" class=".slide_control_btn mr-2 btn1" @click="showPrevImage" style="cursor:pointer;">
      <i class="fas fa-chevron-circle-left slide_control_icon text-primary" size="3x"></i>
    </button>
    <div class="position-relative w-100 redcrouserBtn" >
      <b-carousel id="carousel-fade" indicators :interval="0"  ref="image_carousel">
        <b-carousel-slide v-for="(media, index) in mediaData" :key="index"  >
          <template #img>
            <img class="d-block img-fluid w-100" style="object-fit:contain;" :src="media.image" alt="post image">
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <button  v-if="mediaData.length > 1" class=".slide_control_btn ml-2 btn2" @click="showNextImage" style="cursor:pointer;">
      <i class="fas fa-chevron-circle-right slide_control_icon text-primary" size="3x"></i>
    </button>
  </div>
</template>
<style lang="scss" scoped>
.btn1 {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 20px !important;
  margin: auto !important;
  z-index: 99 !important;
  font-size: 27px;
  border: none !important;
  background: transparent;
}
.btn2 {
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 20px !important;;
  margin: auto !important;
  z-index: 99;
  font-size: 27px;
  border: none !important;
  background: transparent;
}
</style>
<script>
import { socialvue } from "../../config/pluginInit"

export default {
  name: "mediaView",
  props: {
    propMedia: {
      type: Array
    },
    propIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      title: "Media",
      mediaData: [],
      type: ""
    }
  },
  async mounted () {
    socialvue.index()
    this.mediaData = this.propMedia
    setTimeout(() => {
      this.$refs.image_carousel.setSlide(this.propIndex)
    }, 10)
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * showPrevImage ()
     */
    showPrevImage () {
      try {
        this.$refs.image_carousel.prev()
      }
      catch (err) {
        console.error("Exception occurred at showPrevImage () and Exception", err.message)
      }
    },
    /**
     * showPrevImage ()
     */
    showNextImage () {
      try {
        this.$refs.image_carousel.next()
      }
      catch (err) {
        console.error("Exception occurred at showNextImage () and Exception", err.message)
      }
    }
  }
}
</script>
